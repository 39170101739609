/* Container styles */
.unviewed-bids-container {
  padding: 10px;
  max-width: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
  box-sizing: border-box; /* Include padding in width calculation */
}

/* Title styles */
.section-title {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping if needed */
}

.count-badge {
  font-size: 0.85rem;
  padding: 0.3em 0.6em;
  margin-left: 5px;
}

.section-description {
  font-size: 0.85rem;
  margin-bottom: 1.5rem;
  color: #6c757d;
}

/* Card styles */
.customer-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
}

.customer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 12px 10px; /* Reduce padding */
  border-bottom: 1px solid #e9ecef;
}

.customer-email {
  font-size: 0.95rem;
  font-weight: 600;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70%;
}

.bid-count {
  font-size: 0.75rem;
  padding: 0.3em 0.5em;
}

.customer-body {
  padding: 12px 10px; /* Reduce padding */
}

.customer-info {
  margin-bottom: 15px;
}

.info-title {
  font-size: 0.85rem;
  margin-bottom: 8px;
  font-weight: 600;
}

.contact-detail {
  font-size: 0.85rem;
  margin-bottom: 5px;
  word-break: break-all;
  overflow-wrap: break-word;
  line-height: 1.3;
}

/* Button styles */
.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.sms-button, .mark-button {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  font-size: 0.95rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  transition: all 0.3s ease;
}

.sms-button {
  background-color: #0d6efd;
  color: white;
}

.sms-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.mark-button {
  background-color: #198754;
  color: white;
}

.help-text {
  text-align: center;
  font-size: 0.75rem;
  color: #6c757d;
  margin-top: 3px;
}

.icon-space {
  margin-right: 6px;
  font-size: 0.9rem;
}

/* Accordion styles */
.bids-accordion .accordion-header {
  padding: 0;
}

.bids-accordion .accordion-header button {
  padding: 10px;
  width: 100%;
  text-align: left;
  background-color: #f8f9fa;
  font-size: 0.85rem;
  font-weight: 600;
}

.view-text {
  font-size: 0.85rem;
  font-weight: 600;
}

.accordion-body {
  padding: 8px 0;
}

.bid-item {
  background-color: #f9f9f9;
  padding: 12px 10px;
  margin-bottom: 10px;
  border-radius: 6px;
}

.bid-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.bid-title {
  font-size: 0.85rem;
  margin: 0;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}

.bid-amount {
  background-color: #17a2b8;
  color: white;
  padding: 0.2em 0.5em;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 600;
}

.bid-description, .bid-detail {
  font-size: 0.8rem;
  margin-bottom: 6px;
  line-height: 1.3;
  overflow-wrap: break-word;
  word-break: break-word;
}

/* Alert styles */
.mobile-alert {
  font-size: 0.85rem;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 15px;
}

/* Loading indicator */
.loading {
  text-align: center;
  padding: 20px;
  font-size: 0.95rem;
  color: #6c757d;
}

/* Media queries for responsive design */
@media (min-width: 768px) {
  .unviewed-bids-container {
    padding: 20px;
  }
  
  .section-title {
    font-size: 2rem;
  }
  
  .action-buttons {
    flex-direction: row;
  }
  
  .sms-button, .mark-button {
    width: 50%;
  }
  
  .customer-email {
    font-size: 1.2rem;
  }
  
  /* Reset other sizes for desktop */
  .section-description,
  .info-title,
  .contact-detail,
  .bid-description, 
  .bid-detail,
  .bid-title {
    font-size: initial;
  }
}

/* Add new styles for the header with refresh button */
.header-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  flex-wrap: wrap; /* Allow wrapping on small screens */
  gap: 8px;
}

.refresh-button {
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.refresh-button:hover {
  background-color: #5a6268;
}

.refresh-button i {
  font-size: 0.75rem;
}

/* Debug info styles */
.debug-info {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  padding: 8px;
  margin-bottom: 12px;
  border-radius: 6px;
  font-size: 0.75rem;
}

.debug-info h6 {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 0.8rem;
}

.debug-info p {
  margin-bottom: 2px;
}

/* Fix for React Bootstrap specific accordion styles */
.accordion-button {
  padding: 10px !important;
  font-size: 0.85rem !important;
}

.accordion-body {
  padding: 10px !important;
}
