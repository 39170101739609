.gallery-container {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.back-button {
  background: #A328F4;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  font-size: 14px;
  margin-bottom: 20px;
  transition: background 0.3s ease;
}

.back-button:hover {
  background: #8c1ecc;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.gallery-image {
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  cursor: pointer;
}

.gallery-image:hover {
  transform: scale(1.03);
  opacity: 0.9;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .gallery-container {
    padding: 15px;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 5px;
  }

  .gallery-image {
    height: 180px;
  }
}

@media (max-width: 480px) {
  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
  .gallery-image {
    height: 150px;
  }
}
