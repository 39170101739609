.email-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Increased z-index to be above navbar */
}

.email-modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  position: relative;
  z-index: 10000; /* Even higher z-index for the modal content */
}

.email-modal-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #718096;
  transition: color 0.2s;
}

.email-modal-close:hover {
  color: #4a5568;
}

.email-modal-content h3 {
    margin-top: 0;
    word-break: break-word;
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
  }

.email-modal-content input {
  width: 100%;
  padding: 0.5rem;
  margin: 1rem 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.email-modal-content button[type="submit"] {
  width: 100%;
  padding: 0.5rem;
  background: #FF008A;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-icon {
  text-align: center;
  margin-bottom: 1rem;
}

.modal-icon img {
  width: 64px;
  height: 64px;
}

h3 {
  color: #1a1a1a;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  text-align: center;
}

.value-props {
  margin: 1.5rem 0;
}

.main-offer {
  font-size: 1.2rem;
  color: #FF008A;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.benefits-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.benefits-list li {
  margin: 0.5rem 0;
  color: #4a5568;
  font-size: 1rem;
}

.cta-button {
  background: #4299e1;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  width: 100%;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s, background 0.2s;
}

.cta-button:hover {
  background: #2b6cb0;
  transform: translateY(-2px);
}

input[type="email"] {
  padding: 1rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  transition: border-color 0.2s;
}

input[type="email"]:focus {
  border-color: #4299e1;
  outline: none;
}

.trust-signal {
  text-align: center;
  color: #718096;
  font-size: 0.9rem;
  margin-top: 1rem;
}

.trust-signal small {
  display: block;
  margin-top: 0.5rem;
  color: #a0aec0;
}

@media screen and (max-width: 768px) {
  .email-modal-content {
    width: 95%;
    padding: 1.5rem;
    margin: 1rem;
    max-height: 90vh;
    overflow-y: auto;
  }

  .modal-icon img {
    width: 150px;  /* Adjust logo size for mobile */
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }

  .main-offer {
    font-size: 1.1rem;
  }

  .benefits-list li {
    font-size: 0.95rem;
    margin: 0.4rem 0;
  }

  input[type="email"] {
    padding: 0.75rem;
    font-size: 16px; /* Prevents iOS zoom on focus */
  }

  .cta-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }

  .trust-signal {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .email-modal-content {
    padding: 1rem;
  }

  .email-modal-close {
    font-size: 1.25rem;
    right: 0.75rem;
    top: 0.75rem;
  }

  .modal-icon img {
    width: 120px;
  }

  h3 {
    font-size: 1.3rem;
  }
}

/* Add touch-friendly improvements */
@media (hover: none) {
  .cta-button:hover {
    transform: none;
  }

  input[type="email"],
  .cta-button {
    min-height: 44px; /* Minimum tap target size */
  }
}