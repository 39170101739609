.user-request-container {
    position: relative;
    background: white;
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    border: 1px solid #eaeaea;
}

.request-status-badge {
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 6px 16px;
    border-radius: 20px;
    color: white;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: 'Outfit', sans-serif;
}

.request-content {
    padding-right: 100px;
}

.request-title {
    font-size: 20px;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 20px;
}

.request-details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 16px;
}

.detail-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.detail-item-label {
    font-size: 13px;
    color: #6b7280;
    font-weight: 500;
}

.detail-item-value {
    font-size: 15px;
    color: #1f2937;
    font-weight: 500;
}

@media (max-width: 768px) {
    .request-details-grid {
        grid-template-columns: 1fr 1fr;
    }
    
    .request-content {
        padding-right: 0;
        padding-top: 20px;
    }
    
    .request-status-badge {
        top: 12px;
        right: 12px;
    }
}

@media (max-width: 480px) {
    .request-details-grid {
        grid-template-columns: 1fr;
    }
} 