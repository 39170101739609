.admin-content {
  margin-top: 20px;
  width: 100%;
  transition: all 0.3s ease;
}

/* Make the container full width on mobile */
@media (max-width: 768px) {
  .container.px-5 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
  
  .col-lg-12 {
    padding: 0;
    width: 100%;
    max-width: 100%;
  }
  
  h2 {
    padding: 0 15px;
    text-align: center;
  }
  
  .grey-bg {
    background-color: #f8f9fa;
    width: 100%;
  }
  
  .content {
    width: 100%;
    padding: 0;
  }
}

.btn-group .btn:focus {
    box-shadow: none;
}

.btn-group .btn:hover {
    opacity: 0.9;
}
