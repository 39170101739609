.accepted-bids-container {
    padding: 20px;
    width: 100%;
}

.section-title {
    margin-bottom: 20px;
    color: #333;
}

/* Desktop styles */
.desktop-view {
    display: block;
}

.mobile-view {
    display: none;
}

.table {
    width: 100%;
    border-collapse: collapse;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.table th {
    background-color: #f8f9fa;
    padding: 12px;
    text-align: left;
    font-weight: 600;
}

.table td {
    padding: 12px;
    border-top: 1px solid #dee2e6;
}

.new-bids-badge {
    display: inline-block;
    background-color: #ff3875;
    color: white;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
    margin-left: 10px;
    cursor: pointer;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .desktop-view {
        display: none;
    }

    .mobile-view {
        display: block;
    }

    .bid-card {
        background: white;
        padding: 16px;
        margin-bottom: 16px;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }

    .bid-header {
        border-bottom: 1px solid #dee2e6;
        padding-bottom: 8px;
        margin-bottom: 8px;
    }

    .bid-detail {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
    }

    .bid-label {
        font-weight: 600;
        color: #666;
    }

    .bid-value {
        color: #333;
    }
}
