.vendor-search {
    position: relative;
    margin-right: 20px;
}

.search-button {
    background: none;
    border: 1px solid #FF008A;
    color: #FF008A;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Outfit', sans-serif;
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 200px;
}

.search-button:hover {
    background: #FF008A;
    color: white;
}

.search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 16px;
    width: 350px;  /* Increased width to accommodate longer location names */
    margin-top: 8px;
    z-index: 1000;
}

.search-section {
    margin-bottom: 16px;
    transition: all 0.3s ease;
}

.search-section label {
    display: block;
    margin-bottom: 4px;
    font-family: 'Outfit', sans-serif;
    color: #666;
}

.search-section select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: 'Outfit', sans-serif;
    margin-bottom: 8px;
}

.search-submit {
    width: 100%;
    padding: 10px;
    background: #FF008A;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Outfit', sans-serif;
}

.search-submit:hover {
    background: #e6007c;
}
