.newsletter-landing {
    min-height: 100vh;
    padding: 60px 20px;
    background: linear-gradient(to bottom right, #fbfafb, #fff);
  }
  
  .landing-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .landing-content h1 {
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    word-wrap: break-word;
  }
  
  .newsletter-landing h1 {
    font-family: Outfit;
    font-size: 48px;
    font-weight: 700;
    color: #0B1215;
    margin-bottom: 20px;
  }
  
  .thank-you {
    font-size: 20px;
    color: #666;
    margin-bottom: 40px;
  }
  
  .benefits-section {
    background: white;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0px 0px 30px rgba(205, 198, 219, 0.3);
    margin-bottom: 40px;
  }
  
  .benefits-section h2 {
    font-family: Outfit;
    font-size: 28px;
    margin-bottom: 24px;
    color: #0B1215;
  }
  
  .benefits-list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .benefits-list li {
    margin: 16px 0;
    font-size: 18px;
    color: #4a4459;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .cta-section {
    margin-top: 40px;
  }
  
  .cta-section h3 {
    font-family: Outfit;
    font-size: 24px;
    margin-bottom: 20px;
    color: #0B1215;
  }
  
  .button-group {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .cta-button {
    display: inline-block;
    padding: 14px 28px;
    border-radius: 100px;
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .cta-button.primary {
    background: linear-gradient(85deg, #A328F4 9.33%, rgba(255, 0, 138, 0.76) 68.51%);
    color: white;
  }
  
  .cta-button.secondary {
    background: white;
    color: #A328F4;
    border: 2px solid #A328F4;
  }
  
  .cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .coupon-section {
    background: #f8f4ff;
    border-radius: 12px;
    padding: 24px;
    margin: 24px 0;
    text-align: center;
  }
  
  .coupon-display {
    margin: 16px 0;
  }
  
  .coupon-display .coupon-code {
    background: #6b46c1;
    color: white;
    padding: 12px 24px;
    border-radius: 6px;
    font-size: 1.4em;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 8px;
    letter-spacing: 1.5px;
  }
  
  .coupon-display small {
    display: block;
    color: #666;
    margin-top: 8px;
  }
  
  @media (max-width: 768px) {
    .newsletter-landing h1 {
      font-size: 32px;
    }
  
    .benefits-section {
      padding: 24px;
    }
  
    .button-group {
      flex-direction: column;
    }
  
    .cta-button {
      width: 100%;
      text-align: center;
    }
  }