.package-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

.package-card {
    border: 2px solid #9F8AB3;
    border-radius: 8px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.package-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.package-card.selected {
    border-color: #a328f4;
    background-color: rgba(163, 40, 244, 0.05);
}

.package-card h3 {
    color: #a328f4;
    margin-bottom: 10px;
}

.package-card .price {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 15px;
}

.package-card ul {
    list-style: none;
    padding: 0;
}

.package-card li {
    margin-bottom: 8px;
    position: relative;
    padding-left: 20px;
}

.package-card li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: #a328f4;
}

.event-details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.event-summary-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.summary-section {
    background: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.summary-section-title {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #f0f0f0;
}

.summary-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.summary-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.summary-label {
    color: #666;
    font-size: 0.9rem;
    text-transform: uppercase;
}

.summary-value {
    color: #333;
    font-size: 1.1rem;
    font-weight: 500;
}

.summary-comments {
    color: #333;
    line-height: 1.6;
}

.coupon-container {
    padding: 20px;
    border-radius: 8px;
    background-color: #f8f9fa;
}

.coupon-input-group {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.coupon-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.apply-coupon-btn {
    padding: 10px 20px;
    background-color: #a328f4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.apply-coupon-btn:hover {
    background-color: #8a1cd9;
}

.apply-coupon-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.coupon-message {
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
    font-size: 0.9rem;
}

.coupon-message.success {
    background-color: #d4edda;
    color: #155724;
}

.coupon-message.error {
    background-color: #f8d7da;
    color: #721c24;
}

.photo-grid {
    display: grid;
    gap: 10px;
    padding: 10px;
    width: 100%;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 1024px) {
    .photo-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
    }
}

/* For mobile devices */
@media screen and (max-width: 576px) {
    .photo-grid {
        grid-template-columns: 1fr;
        gap: 6px;
        padding: 5px;
    }
}

.photo-grid-item {
    position: relative;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.photo-grid-item:hover {
    transform: scale(1.05);
}

.photo-grid-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
}

.photo-grid-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.photo-grid-image:hover {
    transform: scale(1.05);
}

    .remove-photo-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: gray;
      color: black;
      border: none;
      border-radius: 50%;
      display: none;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 28px;
      height: 28px;
      padding: 4px; 
      gap: 10px;
      flex-shrink: 0;
      border-radius: 29px;
      background: var(--Grey-Medium, #E5E5E5);
      border:none;

    }

    .photo-grid-item:hover .remove-photo-button {
        display: flex;
      }
  
      .remove-photo-button:hover {
        color:white;
      }

.add-more-photos-btn {
    display: flex;
    width: 200px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    border: 1px solid #FF008A;
    background:transparent
  }

  .add-more-photos-btn:hover {
    background-color: #FF008A;
  }

  .add-more-photos-btn:hover .add-more-text {
    color: white;
  }

  .add-more-text {
    color: #FF008A;
    color: #FF008A;
    text-align: center;
    font-family: var(--Label-Large-Font, Roboto);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: var(--Label-Large-Tracking, 0.1px);
  }

  @media (max-width: 1350px) {
    .remove-photo-button {
        /* Always show on mobile */
        display: flex;
        /* Make button bigger for touch */
        width: 32px;
        height: 32px;
        /* Increase contrast */
        background: rgba(0, 0, 0, 0.7);
        color: white;
    }

    .photo-grid {
        gap: 16px; /* Increase gap for mobile */
    }

    .photo-grid-item {
        /* Remove hover effect on mobile */
        transform: none;
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-photo {
    position: relative;
    max-width: 90vw;
    max-height: 90vh;
    background: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content-photo img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
}

.request-summary-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

@media screen and (max-width: 1024px) {
    .request-summary-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 576px) {
    .request-summary-grid {
        grid-template-columns: 1fr;
    }
}

.sub-steps-indicator {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-bottom: 30px;
    padding: 0 1rem;
}

.sub-step {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    border: #A328F4 2px solid;
    transition: all 0.3s ease;
    color: #8a1cd9;
    font-size: 14px;
    white-space: nowrap;
    flex: 0 1 auto;
    text-align: center;
    min-width: fit-content;
}

.sub-step.active {
    background: linear-gradient(85deg, #A328F4 9.33%, rgba(255, 0, 138, 0.76) 68.51%);
    color: white;
}

.sub-step.completed {
    background-color: #A328F4;
    color: white;
}

/* Remove these classes as they're no longer needed */
.sub-step-navigation,
.sub-step-button {
    display: none;
}

/* Add responsive styles */
@media screen and (max-width: 768px) {  
    .sub-steps-indicator {
        gap: 8px;
        margin-bottom: 20px;
    }

    .sub-step {
        padding: 0.4rem 0.8rem;
        font-size: 13px;
    }
}

@media screen and (max-width: 480px) {
    .sub-steps-indicator {
        gap: 6px;
        padding: 0 0.5rem;
    }

    .sub-step {
        padding: 0.3rem 0.6rem;
        font-size: 12px;
        border-width: 1px;
    }
}

/* ...existing styles... */

.wedding-photo-options {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f8f9fa;
    width: 100%;  /* Ensure it takes full width */
    box-sizing: border-box; /* Include padding in width calculation */
}

.photo-options-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;  /* Change to two columns */
    gap: 15px;
    width: 100%;
    align-items: start;
    justify-content: start;
}

/* Update the mobile responsive design */
@media (max-width: 1000px) {
    .photo-options-grid {
        grid-template-columns: 1fr;  /* Single column on mobile */
        gap: 10px;
    }
}

/* ...rest of existing code... */

.photo-option-item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

}

.photo-option-item input[type="radio"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

/* Add this new style */
.wedding-details-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.photo-options-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Outfit;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 8px;
}

/* ...existing styles... */

.form-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
}

.custom-input-container {
    width: 100%;
    margin-bottom: 16px;
}

.wedding-details-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.wedding-photo-options {
    width: 100%;
    margin-top: 0;
    padding: 20px;
    border-radius: 8px;
    background-color: #f8f9fa;
}

.photo-option-item {
    padding: 8px 0;
    border-bottom: 1px solid #eee;
}

.input-with-unknown {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
}

.unknown-checkbox-container {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.unknown-checkbox-container input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.unknown-checkbox-label {
    color: #666;
    font-size: 14px;
    user-select: none;
    white-space: nowrap;  /* Add this line to prevent text wrapping */
}

.custom-input:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

/* Add these new styles */
.radio-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.radio-option {
    display: flex;
    align-items: center;
    gap: 10px;
}

.radio-option input[type="radio"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.radio-option label {
    cursor: pointer;
    font-size: 16px;
    margin: 0;
}

.start-end-time {
    display: flex;
    gap: 10px;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .start-end-time {
        flex-direction: column;
        gap: 10px;
    }
    
}

/* ...existing styles... */

.equipment-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding: 10px 0;
}

.equipment-option-button {
    padding: 15px 20px;
    border: 2px solid #9F8AB3;
    border-radius: 8px;
    background-color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
    font-size: 16px;
    width: 100%;
}

.equipment-option-button:hover {
    border-color: #a328f4;
    background-color: rgba(163, 40, 244, 0.05);
}

.equipment-option-button.selected {
    border-color: #a328f4;
    background-color: rgba(163, 40, 244, 0.05);
    color: #a328f4;
}

@media (max-width: 768px) {
    .equipment-option-button {
        padding: 12px 16px;
        font-size: 14px;
    }
}

/* Add styles for the flower images */
.flower-image {
    width: 160px; /* Adjust the size as needed */
    height: 160px; /* Adjust the size as needed */
    object-fit: cover;
    border-radius: 8px;
    margin-right: 10px;
}

.request-subtype {
    font-weight: 600;
    color: #666;
    margin-bottom: 5px;
    font-size: 0.9rem;
}

.request-info {
    color: #333;
    font-size: 1rem;
    line-height: 1.4;
}

.selected-vendor-info {
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    padding:0;
    white-space: normal; /* Allow line breaks */
    overflow: visible;   /* Allow overflow */
    word-wrap: break-word; /* Break long words onto the next line */
    overflow-wrap: break-word; 
}

.vendor-profile-image {
    margin-right: 8px;
    width: 80px !important; /* Set a fixed width */
    height: 80px !important; /* Set a fixed height */
    object-fit: cover !important; /* Scale the image to cover the container */
}
