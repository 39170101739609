/* Ensure MessagingView stays inside the dashboard area */
.messaging-container {
    flex: 1; /* Takes full available space within .dashboard-main */
    max-width: 800px; /* Ensures it doesn't stretch too far */
    margin: 20px auto; /* Centers it */
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 80vh; /* Keep it contained */
  }
  
  /* Make sure the main dashboard is properly aligned */
  .dashboard-main {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content */
    padding: 20px;
    flex: 1;
    width: 100%;
    overflow: hidden;
  }
  
  /* Messages list */
  .messages-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  /* Message bubbles */
  .message {
    padding: 12px 16px;
    border-radius: 18px;
    max-width: 75%;
    word-wrap: break-word;
  }
  
  /* Sent messages */
  .sent {
    background-color: #9f1ae2c2;
    color: white;
    align-self: flex-end;
  }
  
  /* Received messages */
  .received {
    background-color: #eee;
    color: #333;
    align-self: flex-start;
  }
  
  /* Message input field */
  .message-input {
    display: flex;
    width: 100%;
    padding: 10px;
    border-top: 2px solid #eee;
  }
  
  .input-field {
    flex-grow: 1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .send-button {
    background-color: #9f1ae2c2;
    color: white;
    border: none;
    padding: 10px 15px;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #9f1ae2;
  }