.bids-page {
    padding: 20px;
}

.bids-page .tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: center;
}

.bids-page .tab {
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    background: transparent;
    color: black;
    cursor: pointer;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease;
    border: 1px solid #A328F4;
}

.bids-page .tab:hover {
    background: linear-gradient(85deg, #A328F4 9.33%, rgba(255, 0, 138, 0.76) 68.51%);
    color: white;
}

.bids-page .tab.active {
    background: linear-gradient(85deg, #A328F4 9.33%, rgba(255, 0, 138, 0.76) 68.51%);
    color: white;
}

.bids-page .bids-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
    width: 100%;
    max-width: 1000px; /* Increased from 800px */
    margin: 0 auto; /* Center the container */
}

.bids-page .approved-bid-card {
    width: 100%;
    min-width: 100%;
    padding: 24px;
    margin-bottom: 20px;
    /* ...rest of existing styles... */
}

/* Remove the .bid-card styles since we're using BidDisplay */

.bids-page .request-swiper-container {
    position: relative;
    margin: 0 auto 30px;
    padding: 0;
    width: calc(100% - 80px); /* Slightly narrower container */
    max-width: 1000px;
    min-height: 300px;        /* Add minimum height */    /* Add padding at bottom for buttons */
}

.bids-page .swiper {
    height: 100%;            /* Ensure swiper takes full height */
    padding-bottom: 60px;    /* Match container padding */
}

@media (max-width:768px) {
    .bids-page .swiper {
        padding-bottom: 0; /* Remove padding on mobile */
    }
}

.bids-page .swiper-slide {
    height: auto !important; /* Force all slides to adapt to content */
    display: flex;
    justify-content: center;
    align-items: flex-start;  /* Align to top instead of center */
    padding-bottom: 60px;     /* Space for buttons */
}

.bids-page .request-slide {
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.bids-page .request-display-mini {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    min-height: fit-content;
}

/* Make both types of request displays consistent */
.bids-page .request-display {
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* Ensure grids have consistent spacing */


.bids-page .detail-item,
.bids-page .request-info {
    min-height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.bids-page .no-requests {
    text-align: center;
    padding: 20px;
    color: #666;
    font-size: 1.1em;
}

.bids-page .request-navigation {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}

.bids-page .request-nav-button {
    background: transparent;
    border: 1px solid #A328F4;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.bids-page .request-nav-button:hover {
    background: linear-gradient(85deg, #A328F4 9.33%, rgba(255, 0, 138, 0.76) 68.51%);
    color: white;
}

/* Add these styles for the Swiper navigation */
.bids-page .request-swiper-container .swiper-button-prev,
.bids-page .request-swiper-container .swiper-button-next {
    background-color: white;
    width: 35px;  /* Smaller button size */
    height: 35px; /* Smaller button size */
    border-radius: 50%;
    border: 1px solid #A328F4;
    position: absolute;
    top: 50%;
    transform: translateY(0);
    z-index: 1;
}

.bids-page .request-swiper-container .swiper-button-prev::after,
.bids-page .request-swiper-container .swiper-button-next::after {
    font-size: 18px;
    color: #A328F4;
    font-weight: bold;
}

.bids-page .request-swiper-container .swiper-button-prev:hover,
.bids-page .request-swiper-container .swiper-button-next:hover {
    background: linear-gradient(85deg, #A328F4 9.33%, rgba(255, 0, 138, 0.76) 68.51%);
}

.bids-page .request-swiper-container .swiper-button-prev:hover::after,
.bids-page .request-swiper-container .swiper-button-next:hover::after {
    color: white;
}

.bids-page .request-swiper-container .swiper-button-disabled {
    opacity: 0.35;
    cursor: not-allowed;
}

.bids-page .request-swiper-container .swiper-button-prev {
    left: -60px; /* Position outside the container */
}

.bids-page .request-swiper-container .swiper-button-next {
    right: -60px; /* Position outside the container */
}

.bids-page .section-title {
    color: var(--Black-900, #000);
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
}

.bids-page .section-description {
    color: var(--Black-800, #404040);
    text-align: center;
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
}

.bids-page .request-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 24px;
    width: 100%;
    height: auto;
}

.bids-page .request-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

.bids-page .request-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 100%;
    margin: 20px 0;
}

.bids-page .detail-row {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    background-color: #f8f9fa;
    border-radius: 8px;
    text-align: center;
}

.bids-page .detail-label {
    color: #666;
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 4px;
}

/* Add swipe indicator styles */
.bids-page .swipe-indicator {
    display: none;  /* Hidden by default on desktop */
    text-align: center;
    color: #666;
    font-size: 0.9rem;
    margin: 10px 0;
    opacity: 0.7;
    font-family: Outfit;
    position: relative;  /* Add this */
    z-index: 2;         /* Add this */
}

.bids-page .swipe-indicator::before {
    content: '←';
    margin-right: 8px;
}

.bids-page .swipe-indicator::after {
    content: '→';
    margin-left: 8px;
}

@media (max-width: 1200px) {
    .bids-page .request-swiper-container {
        width: calc(100% - 80px);
    }

    .bids-page .request-swiper-container .swiper-button-prev {
        left: -40px;
    }

    .bids-page .request-swiper-container .swiper-button-next {
        right: -40px;
    }
}

@media (max-width: 768px) {
    .bids-page .tabs {
        flex-direction: column;
        overflow-x: auto;
        padding-bottom: 10px;
    }

    .bids-page .tab {
        font-size: 14px;
        padding: 8px 16px;
        white-space: nowrap;
    }

    .bids-page .bids-container {
        grid-template-columns: 1fr; /* Single column on mobile */
    }

    .bids-page .request-swiper-container {
        width: 100%; /* Full width on mobile */
        padding: 0 10px; /* Add some padding */
        margin-bottom: 0; /* Reduce margin to bring indicator closer */
    }

    .bids-page .request-swiper-container .swiper-button-prev,
    .bids-page .request-swiper-container .swiper-button-next {
        display: none !important; /* Hide navigation buttons on mobile */
    }

    .bids-page .swipe-indicator {
        display: block; /* Show swipe indicator on mobile */
        margin: 15px 0;
        font-weight: 500;
        padding: 10px 0;
        margin: 0 0 20px 0;
        background: white;  /* Add this */
    }

    .bids-page .request-slide {
        padding: 0; /* Remove padding since buttons are hidden */
    }

    .bids-page .request-swiper-container {
        width: calc(100% - 30px); /* Much narrower container on mobile */
    }

    .bids-page .request-swiper-container .swiper-button-prev,
    .bids-page .request-swiper-container .swiper-button-next {
        width: 15px;  /* Much smaller buttons */
        height: 15px;
        min-width: 15px; /* Prevent button growth */
        min-height: 15px;
    }

    .bids-page .request-swiper-container .swiper-button-prev {
        left: -15px;
    }

    .bids-page .request-swiper-container .swiper-button-next {
        right: -15px;
    }

    .bids-page .request-swiper-container .swiper-button-prev::after,
    .bids-page .request-swiper-container .swiper-button-next::after {
        font-size: 8px; /* Much smaller arrows */
        font-weight: 900; /* Make arrows bolder so they're more visible */
    }

    .bids-page .request-slide {
        padding: 0 5px; /* Reduce padding on mobile */
    }

    .bids-page .request-swiper-container .swiper-button-prev,
    .bids-page .request-swiper-container .swiper-button-next {
        width: 25px;  /* Even smaller on mobile */
        height: 25px; /* Even smaller on mobile */
    }

    .bids-page .request-swiper-container .swiper-button-prev {
        left: -25px; /* Closer to content */
    }

    .bids-page .request-swiper-container .swiper-button-next {
        right: -25px; /* Closer to content */
    }

    .bids-page .request-swiper-container .swiper-button-prev::after,
    .bids-page .request-swiper-container .swiper-button-next::after {
        font-size: 12px; /* Smaller arrows */
    }

    .bids-page .swipe-indicator {
        display: block;  /* Show on mobile */
    }   

    .bids-page .request-display-mini {
        min-height: auto;
    }

    .bids-page .detail-item {
        min-height: auto;
        padding: 10px 0;
    }

    .bids-page .request-content {
        padding: 15px;
        height: auto;
    }

    .bids-page .request-title {
        font-size: 1.2rem;
        line-height: 1.4;
    }

    .bids-page .detail-value, 
    .bids-page .detail-label {
        font-size: 0.9rem;
    }

    .bids-page .section-title {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .bids-page .section-description {
        font-size: 13px;
        padding: 0 15px;
    }

    .bids-page .request-card {
        padding: 16px;
    }

    .bids-page .request-header {
        margin-bottom: 16px;
    }

    .bids-page .request-details {
        grid-template-columns: 1fr 1fr; /* Keep 2 columns even on mobile */
        gap: 10px;
    }

    .bids-page .detail-row {
        padding: 8px;
    }

    .bids-page .detail-label {
        font-size: 0.8rem;
    }

    .bids-page .detail-value {
        font-size: 0.9rem;
    }
}

.bids-page .no-bids-message {
    text-align: center;
    padding: 30px;
    background: #f8f9fa;
    border-radius: 8px;
    margin: 20px 0;
}

.bids-page .no-bids-message p {
    margin-bottom: 15px;
    color: #666;
    font-size: 1rem;
}

.bids-page .no-bids-message button {
    padding: 8px 20px;
    border-radius: 20px;
    background: white;
    border: 1px solid #A328F4;
    color: #A328F4;
    font-weight: 500;
    transition: all 0.3s ease;
}

.bids-page .no-bids-message button:hover {
    background: linear-gradient(85deg, #A328F4 9.33%, rgba(255, 0, 138, 0.76) 68.51%);
    color: white;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    max-width: 1000px;
    width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
    margin-top: 0;
    margin-bottom: 16px;
}

.modal-content ul {
    margin: 16px 0;
    padding-left: 20px;
}

.modal-content li {
    margin-bottom: 8px;
}

.modal-buttons {
    display: flex;
    justify-content:center;
    align-items: center;
    gap: 12px;
    margin-top: 24px;
    flex-direction: row !important;
}

@media (max-width: 768px) {
    .modal-buttons {
        flex-direction: column !important;
        width: 100%;
    }
}

.modal-buttons button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}


