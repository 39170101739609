.unsubscribe-container {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: linear-gradient(to bottom right, #fbfafb, #fff);
}

.unsubscribe-card {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0px 0px 30px rgba(205, 198, 219, 0.3);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

.unsubscribe-card h2 {
  font-family: Outfit;
  font-size: 28px;
  color: #0B1215;
  margin-bottom: 20px;
}

.unsubscribe-card p {
  color: #666;
  margin-bottom: 16px;
  line-height: 1.5;
}

.error h2 {
  color: #dc3545;
}

.success h2 {
  color: rgba(255, 0, 138, 0.76);
}

.email-input {
  width: 100%;
  padding: 12px;
  margin: 16px 0;
  border: 2px solid #eee;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.email-input:focus {
  border-color: rgba(255, 0, 138, 0.76);
  outline: none;
}

.unsubscribe-button {
  background: rgba(255, 0, 138, 0.76);
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.unsubscribe-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.unsubscribe-button:hover:not(:disabled) {
  opacity: 0.9;
}

form {
  text-align: center;
}
