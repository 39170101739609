/* Remove or comment out the horizontal status bar styles */
/* .status-bar { ... } */
/* .status-step { ... } */
/* .step-circle { ... } */
/* .step-line { ... } */
/* .step-label { ... } */

.status-bar-container {
    flex-direction: row;
    justify-content: center;
    padding:0px;
    overflow-x: auto;
    display: flex;
    width: 100%;
}

.status-steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.status-check-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #D9D9D9;
    color: #4F4F4F;
    font-size: 14px;
    z-index: 1;  /* Ensure circles appear above lines */
}

.status-line {
    width: 2px;  /* Thin vertical line */
    height: 60px;  /* Increased from 40px */
    background: #D9D9D9;
}

.status-text-container {
    display: flex;
    flex-direction: column;
    gap: 64px;  /* Increased from 44px to match new line height */
}

.status-text {
    color: #4F4F4F;
    font-size: 14px;
    white-space: nowrap;
    height: 32px;  /* Match height of circles */
    display: flex;
    align-items: center;
}

/* Keep existing active/completed styles */
.status-check-container.active,
.status-check-container.completed {
    background: #FF008A;
    color: white;
}

.status-line.completed {
    background: #FF008A;
}

.status-text.active {
    color: #FF008A;
    font-weight: 600;
}

/* Add styles for the mobile version */
.mobile-status-bar {
    padding: 20px;
    margin: 0 auto;
    width: 100%;
}

/* Style the progress bar container */
.mobile-status-bar .RSPBprogressBar {
    height: 12px;  /* Increased from 4px */
    background-color: #D9D9D9;
    margin: 10px 0;
}

/* Style the progress bar filled portion */
.mobile-status-bar .RSPBprogression {
    background: #A328F4;
    height: 12px;
}

/* Remove dot styles as they're no longer needed */
/* Media query for mobile */
@media (max-width: 1350px) {
    .status-bar-container {
        display: none;
    }

    .mobile-status-bar {
        display: block;
    }
}

/* Hide mobile version on desktop */
@media (min-width: 1351px) {
    .mobile-status-bar {
        display: none;
    }
}

.request-form-status-container {
    margin: 20px 0;  /* Add margin above and below the status bar */
    display: flex;
    flex-direction: row;
    align-items: stretch; /* Changed from center to stretch */
    padding: 20px;
    border-radius: 8px;
    background: #F7ECFF;
}

.request-form-box {
    display: flex;
    width: 100%;
    height: 100%; /* Add this */
}

.desktop-only {
    display: block;
}

.mobile-only {
    display: none;
    background-color: #fafafa;
    padding: 0px;
    margin: 0;
    width: 100%;
}

@media screen and (max-width: 1350px) {
    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
    }
}

