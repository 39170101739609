.filters-container-SEO {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
    width: 100%;
    max-width: 1200px;
    justify-content: left;
    align-items: left;
}

.filter-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow-x: auto;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.filter-group::-webkit-scrollbar {
    display: none;
}

.filter-group h3 {
    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.filter-title {
    font-family: Outfit, sans-serif;
    text-align: left;
    font-weight: bold;
}

.filter-button-SEO {
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    background-color: white;
    color: #9633eb;
    border-radius: 50px;
    flex: 0 0 auto; /* Prevent button shrinking */
    white-space: normal;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.2;
    padding: 8px 15px;
}

.filter-button-SEO.active {
    background-color: #FF008A;
    color: #fff;
    border-color: #FF008A;
}

.filter-button-SEO:hover {
    background-color: lightgray;
}

@media screen and (max-width: 768px) {
    .filters-container-SEO {
        padding: 0 15px;
        gap: 15px;
    }

    .filter-group {
        gap: 8px;
    }

    .filter-title {
        font-size: 1rem;
        margin-bottom: 8px;
    }

    .filter-button-SEO {
        padding: 8px 15px;
        font-size: 0.9rem;
        width: auto; /* Override the 50% width */
        text-align: center;
        white-space: normal;
        overflow: visible;
        text-overflow: unset;
        height: auto;
        min-height: 40px;
        flex: 0 0 auto;
    }

    h1 {
        font-size: 1.5rem;
        text-align: center;
        padding: 0 15px;
        margin-top: 15px;
        text-overflow: clip;
        white-space: normal;
        overflow: visible;
        height: auto;
    }
    h3 {
        text-overflow: clip;
        white-space: normal;
        overflow: visible;
        height: auto;
    }
}

@media screen and (max-width: 480px) {
    .filter-button-SEO {
        width: auto; /* Override the 100% width */
    }
}
