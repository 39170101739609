.articles-navigation-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.articles-navigation-title {
    font-family: 'Outfit', sans-serif;
    font-size: 2.5rem;
    color: #000;
    text-align: center;
    margin-bottom: 20px;
}

.articles-navigation-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
    max-width: 1200px; /* Add max-width to contain list */
}

.article-navigation-item {
    margin-bottom: 20px; /* Space between articles */
    font-family: 'Outfit', sans-serif;
    width: 100%; /* Ensure item takes full width */
}

.article-preview {
    display: flex; /* Align image and text side by side */
    border: 1px solid #ddd; /* Optional border */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    width: 100%; /* Ensure preview takes full width */

}

.article-image {
    width: 300px; /* Set a fixed width for the image */
    height: 300px; /* Maintain aspect ratio */
    object-fit: cover;
    border-radius: 8px;
}

.article-content {
    padding: 10px;
    flex: 1;
    min-width: 0; /* This prevents flex items from overflowing */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
}

.article-content p {
    font-size: 14px; /* Adjust description size */
    color: #555; /* Change text color */
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.article-navigation-item h2 {
    font-size: 32px;
    color: #333;
    font-family: 'Outfit', sans-serif;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    margin-bottom: 15px;
}

.article-navigation-item p {
    font-size: 20px;
    color: #666;
    text-align: left;
} 

.article-container {
    padding: 20px 20%; 
}

.article-container h1 {
    font-family: 'Outfit', sans-serif;
    font-size: 56px;
    color: #000;
    text-align: center;
    text-wrap: balance;
}

.article-container h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-family: 'Outfit', sans-serif;
    margin: 40px 0px;
    text-wrap: balance;
}

.article-container h3 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: 'Outfit', sans-serif;
    margin: 40px 0px;
    text-wrap: balance;
}

.article-container h4 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Outfit', sans-serif;
    margin: 40px 0px;
}

.article-container p {
    color: #6d6d6d;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 150%; /* 30px */
    text-align: left;
    margin-bottom: 20px;
}
.article-container li {
    color: #5c5c5c;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
}

.article-container-image {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
}

.article-container a.reference_link {
    color: #ff36a3 !important; /* Desired pink color */
    text-decoration: none !important; /* Remove underline */
}

.article-container a.reference_link:hover {
    text-decoration: underline; /* Add underline on hover for better UX */
}

.article-container a {
    color: #ff36a3 !important; /* Desired pink color */
    text-decoration: none !important; /* Remove underline */
}

.article-container a:hover {
    text-decoration: underline; /* Add underline on hover for better UX */
}

.reference-link {
    color: #ff36a3 !important; /* Change to your desired color */
    text-decoration: none !important; /* Remove underline */
}

.reference-link:hover {
    text-decoration: underline; /* Add underline on hover for better UX */
}

a {
    color: inherit; /* Inherit color from parent */
    text-decoration: none; /* Remove underline */
}

.pricing-table {
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Outfit', sans-serif;
    font-size: 20px;
    color: #333;
}

th, td {
    padding: 12px 16px;
    text-align: left;
}

th {
    font-weight: 600;
    border-bottom: 2px solid #e0e0e0;
    border-right: 2px solid #e0e0e0;
}

td {
    border-bottom: 1px solid #e0e0e0;
    border-right: 2px solid #e0e0e0;
}

.pricing-table {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

/* Add these styles for the featured guide section */

.featured-guide {
  background: linear-gradient(135deg, #f7f4ff 0%, #fff1f9 100%);
  border-radius: 16px;
  margin-bottom: 40px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(163, 40, 244, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.featured-guide:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(163, 40, 244, 0.15);
}

.featured-guide-content {
  display: flex;
  align-items: center;
  padding: 30px;
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.featured-guide-text {
  flex: 1;
  padding-right: 30px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.featured-guide-image {
  flex: 0 0 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.featured-guide-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.featured-label {
  display: inline-block;
  background: linear-gradient(91deg, #A328F4 34.17%, #FF008A 86.83%);
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 20px;
  margin-bottom: 15px;
}

.featured-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #0B1215;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.featured-description {
  color: #4a4459;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.download-guide-button {
  background: linear-gradient(91deg, #A328F4 34.17%, #FF008A 86.83%);
  color: white;
  border: none;
  border-radius: 30px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, opacity 0.2s;
  box-shadow: 0 4px 10px rgba(163, 40, 244, 0.25);
}

.download-guide-button:hover {
  transform: translateY(-2px);
  opacity: 0.95;
}

.section-title {
  font-size: 24px;
  margin-bottom: 25px;
  color: #0B1215;
  font-weight: 600;
}

/* Responsive styles for featured guide */
@media (max-width: 900px) {
  .featured-guide-content {
    flex-direction: column-reverse;
    padding: 20px;
  }

  .featured-guide-text {
    padding-right: 0;
    padding-top: 20px;
    text-align: center;
  }

  .featured-guide-image {
    flex: 0 0 auto;
    margin-bottom: 20px;
    max-width: 250px;
    margin: 0 auto;
  }

  .featured-title {
    font-size: 24px;
  }
  
  .download-guide-button {
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
    padding: 10px 20px;
  }
  
  .button-container {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .featured-guide {
    margin-bottom: 30px;
  }
  
  .featured-guide-content {
    padding: 15px;
  }
  
  .featured-label {
    font-size: 12px;
    padding: 4px 10px;
  }
  
  .featured-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .featured-description {
    font-size: 14px;
    margin-bottom: 15px;
  }
  
  .featured-guide-image {
    max-width: 200px;
  }
  
  .download-guide-button {
    font-size: 14px;
    padding: 8px 16px;
  }
  
  .section-title {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
}

@media (max-width: 1300px) {
  .articles-navigation-container {
    padding: 10px; /* Reduce padding on mobile */
    margin: 0;
  }

  .articles-navigation-title {
    font-size: 1.8rem; /* Smaller title size */
    margin-bottom: 15px;
  }

  .articles-navigation-list {
    padding: 0; /* Remove padding */
  }

  .article-navigation-item {
    margin-bottom: 15px; /* Space between articles */
  }

  .article-preview {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center align items */
    padding: 15px;
    gap: 15px;
  }

  .article-image {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
  }

  .article-content {
    text-align: center; /* Center text on mobile */
    padding: 0;
  }

  .article-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 1.3;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
  }

  .article-content p {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
  }

  .article-container {
    padding: 20px 10%;
  }
  
  .article-container h1 {
    font-size: 36px;
  }
  
  .article-container h2 {
    font-size: 24px;
  }
  
  .article-container h3 {
    font-size: 20px;
  }
  
  .article-container h4 { 
    font-size: 18px;
  }
  
  .article-container p {
    font-size: 16px;
  }
  
  .article-container li {
    font-size: 16px;
  }
}

/* Add button container styles */
.button-container {
  display: flex;
  justify-content: flex-start;
}

/* Add smaller screen size adjustments */
@media (max-width: 480px) {
    .articles-navigation-container {
        padding: 8px;
    }

    .articles-navigation-title {
        font-size: 1.5rem;
    }

    .article-preview {
        padding: 10px;
    }

    .article-image {
        height: 180px;
    }

    .article-content h2 {
        font-size: 20px;
        line-height: 1.3;
        margin: 0;
    }

    .article-content p {
        font-size: 14px;
    }

    .article-container {
        padding: 20px 10%;
    }
}

/* Add touch-friendly improvements */
@media (hover: none) {
    .article-navigation-item {
        -webkit-tap-highlight-color: transparent;
    }

    .article-preview {
        transition: transform 0.2s;
    }

    .article-preview:active {
        transform: scale(0.98);
    }
}

@media (max-width: 768px) {
    .article-preview {
        flex-direction: column;
        padding: 15px;
        gap: 15px;
        margin: 0 auto; /* Center the preview */
        width: 95%; /* Slightly less than full width */
    }

    .article-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        margin: 0; /* Remove any margin */
    }

    .article-content {
        width: 100%;
        padding: 0;
    }

    .article-content h2 {
        font-size: 24px;
        margin: 10px 0;
        text-align: left; /* Keep text left-aligned */
    }

    .article-content p {
        font-size: 16px;
        text-align: left;
        margin: 0;
        width: 100%;
    }
}

/* Add extra small screen size adjustments (down to 300px) */
@media (max-width: 480px) {
  .featured-guide-content {
    padding: 24px;
  }
  
  .featured-guide-image {
    max-width: 180px;
  }
  
  .featured-title {
    font-size: 18px;
    margin-bottom: 8px;
    padding: 0 20px;
  }
  
  .featured-description {
    font-size: 13px;
    line-height: 1.4;
  }
  
  .download-guide-button {
    padding: 8px 12px;
    font-size: 13px;
  }
  
  .article-content h2 {
    font-size: 18px;
  }
  
  .article-content p {
    font-size: 13px;
  }
}

@media (max-width: 400px) {
  .articles-navigation-container {
    padding: 6px;
  }
  
  .articles-navigation-title {
    font-size: 1.3rem;
    margin-bottom: 12px;
  }
  
  .featured-label {
    font-size: 11px;
    padding: 3px 8px;
    margin-bottom: 10px;
  }
  
  .section-title {
    font-size: 18px;
    margin-bottom: 15px;
  }
  
  .article-preview {
    padding: 8px;
    gap: 10px;
  }
  
  .article-image {
    height: 160px;
  }
  
  .featured-guide-image {
    max-width: 150px;
  }
}

@media (max-width: 380px) {
  .articles-navigation-title {
    font-size: 1.2rem;
  }
  
  .featured-title {
    font-size: 16px;
  }
  
  .featured-description {
    font-size: 12px;
    margin-bottom: 12px;
  }
  
  .download-guide-button {
    padding: 6px 10px;
    font-size: 12px;
  }
  
  .article-content h2 {
    font-size: 16px;
  }
  
  .article-content p {
    font-size: 12px;
  }
  
  .article-image {
    height: 140px;
  }
  
  .featured-guide-image {
    max-width: 120px;
  }
  
  .featured-guide-content {
    padding: 10px 8px;
    width: auto;
    flex-direction: column-reverse;
  }
  
  .featured-title {
    font-size: 15px;
    margin-bottom: 8px;
    padding: 0;
    width: 100%;
  }
  
  .featured-description {
    font-size: 11px;
    line-height: 1.3;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .featured-guide-text {
    width: 100%;
    padding-right: 0;
  }
}

/* Improve mobile support for extremely narrow screens */
@media (max-width: 320px) {
  .articles-navigation-title {
    font-size: 1.1rem;
    word-wrap: break-word;
    max-width: 100%;
    padding: 0 5px;
  }
  
  .featured-guide {
    margin: 0 2px 25px 2px;
  }
  
  .featured-guide-content {
    padding: 10px 8px;
  }
  
  .featured-label {
    font-size: 10px;
    padding: 2px 6px;
    margin-bottom: 6px;
  }
  
  .featured-title {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .featured-description {
    font-size: 10px;
    line-height: 1.3;
    margin-bottom: 8px;
  }
  
  .download-guide-button {
    padding: 4px 6px;
    font-size: 10px;
    max-width: 95%;
  }
  
  .featured-guide-image {
    max-width: 80px;
  }
  
  .article-preview {
    padding: 5px;
    border-width: 1px;
  }
  
  .article-image {
    height: 100px;
  }
  
  .article-content {
    padding: 0;
  }
  
  .article-content h2 {
    font-size: 13px;
    margin: 5px 0;
  }
  
  .article-content p {
    font-size: 10px;
  }
  
  .articles-navigation-list {
    width: calc(100% - 10px);
  }
}

/* Fix any extreme edge cases below 300px */
@media (max-width: 300px) {
  .featured-guide-content {
    padding: 8px 5px;
  }
  
  .featured-label {
    font-size: 10px;
    padding: 2px 6px;
    margin-bottom: 6px;
  }
  
  .featured-title {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .featured-description {
    font-size: 10px;
    margin-bottom: 8px;
  }
  
  .download-guide-button {
    padding: 4px 6px;
    font-size: 10px;
    max-width: 95%;
  }
  
  .featured-guide-image {
    max-width: 80px;
  }
  
  .article-preview {
    padding: 5px;
    border-width: 1px;
  }
  
  .article-image {
    height: 100px;
  }
  
  .article-content {
    padding: 0;
  }
  
  .article-content h2 {
    font-size: 13px;
    margin: 5px 0;
  }
  
  .article-content p {
    font-size: 10px;
  }
}

.responsive-table-container {
    width: 100%;
    overflow-x: auto;
    margin: 20px 0;
}

.responsive-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
}

.responsive-table th,
.responsive-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
}

.responsive-table th {
    background-color: #f8f8f8;
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .responsive-table thead {
        display: none;
    }
    
    .responsive-table tr {
        margin-bottom: 20px;
        display: block;
        border: 1px solid #ddd;
    }
    
    .responsive-table td {
        display: block;
        text-align: right;
        padding: 12px;
        border: none;
        border-bottom: 1px solid #ddd;
    }
    
    .responsive-table td:last-child {
        border-bottom: none;
    }
    
    .responsive-table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
    }
}

.related-articles {
    margin: 60px 0;
    padding: 20px 0;
    border-top: 1px solid #eee;
}

.related-articles h2 {
    text-align: center;
    margin-bottom: 30px;
}

.related-articles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    margin: 0 auto;
}

.related-article-card {
    border: 1px solid #eee;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    background: white;
    text-decoration: none;
}

.related-article-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.related-article-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.related-article-content {
    padding: 20px;
}

.related-article-content h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
    font-family: 'Outfit', sans-serif;
}

.related-article-content p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .related-articles {
        margin: 40px 0;
        padding: 20px;
    }

    .related-articles-grid {
        grid-template-columns: 1fr;
    }

    .related-article-content h3 {
        font-size: 18px;
    }

    .related-article-content p {
        font-size: 14px;
    }
}



